import { Tab, Tabs, Typography } from "@material-ui/core";
import React, { useContext, useState } from 'react';
import AceEditor from "react-ace";
import "../../../node_modules/ace-builds/src-noconflict/mode-json";
import "../../../node_modules/ace-builds/src-noconflict/mode-typescript";
import "../../../node_modules/ace-builds/src-noconflict/theme-tomorrow_night_eighties";
import { AppContext } from "../../context/app.context";
import NoResponse from "../NoResponse/NoResponse";
import ResponseDetails from "../ResponseDetails/ResponseDetails";
import ResponseHeaders from "../ResponseHeaders/ResponseHeaders";
import './ResponseSection.scss';

const ResponseSection: React.FC = () => {
	const { loading, response } = useContext(AppContext);
	const [responseTab, setResponseTab] = useState<number>(0);

	return (
		<div className={"Response"}>
			{
				response &&
				<>
					<ResponseDetails />

					<Tabs
						variant={"fullWidth"}
						value={responseTab}
						onChange={(e, value) => setResponseTab(value)}
					>
						<Tab disabled={loading} label="Body" />
						<Tab disabled={loading} label="Headers" />
					</Tabs>
					<div className={"responseTabsRoot"}>
						{
							loading
								? <Typography className={"responseLoading"} variant={"body1"}>Loading...</Typography>
								: responseTab === 0 &&
								<AceEditor
									className={"responseEditor"}
									mode={"json"}
									theme={"tomorrow_night_eighties"}
									value={response.json}
									showGutter={true}
									highlightActiveLine={false}
									readOnly={true}
									maxLines={Infinity}
									minLines={20}
									width={"100%"}
									cursorStart={undefined}
								/>
						}
						{responseTab === 1 && <ResponseHeaders />}
					</div>
				</>
			}
			{
				!response &&
				<NoResponse />
			}
		</div>
	);
}

export default ResponseSection;
