import HttpRequest from "../models/httpRequest";
import HttpResponse from "../models/httpResponse";
import { HttpKeyValue } from "../models/requestParam";

export default class HttpService {
	/**
	 * execute request
	 */
	public async execute(request: HttpRequest) {
		let info: RequestInit = { method: request.method };
		let headers = new Headers();
		request.headers.forEach((item) => item.key ? headers.append(item.key, item.value) : null);

		if (!["GET", "HEAD"].includes(request.method))
			headers.append("Access-Control-Allow-Origin", request.contentType);
		else
			headers.delete("content-type");
		info.headers = headers;

		let params = request.params.length > 0
			? "?" + request.params
				.filter(item => item.key)
				.map((item) => item.key + "=" + item.value)
				.join("&")
			: "";

		info.body = this.getBody(request);

		try {
			let response = await fetch(
				request.url + request.path + params,
				info
			);
			let httpResponse = new HttpResponse(response);
			httpResponse.json = JSON.stringify(await response.json(), null, 4);
			return httpResponse;
		}
		catch (e) {
			console.log(e);
			let response = new HttpResponse();
			let responseHeaders: HttpKeyValue[] = [];
			headers.forEach((value, key) => responseHeaders.push({ key, value }));
			response.headers = responseHeaders;
			response.status = 400;
			response.statusText = "Network Error";
			response.url = request.url + request.path + params;
			response.json = e.message ?? e;
			return response;
		}
	}

	private getBody(request: HttpRequest) {
		if (["GET", "HEAD"].includes(request.method)) {
			return undefined;
		} else {
			return request.body ? JSON.stringify(request.body) : undefined;
		}
	}
}