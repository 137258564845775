import { Tab, Tabs, Typography } from "@material-ui/core";
import React, { useContext, useState } from 'react';
import AceEditor from "react-ace";
import "../../../node_modules/ace-builds/src-noconflict/mode-json";
import "../../../node_modules/ace-builds/src-noconflict/mode-typescript";
import "../../../node_modules/ace-builds/src-noconflict/theme-tomorrow_night_eighties";
import { AppContext } from "../../context/app.context";
import HeaderValues from "../HeaderValues/HeaderValues";
import InstallPrompt from "../InstallPrompt/InstallPrompt";
import Params from "../Params/Params";
import RequestBar from "../RequestBar/RequestBar";
import './RequestSection.scss';


const RequestSection: React.FC = () => {
	const { loading, request, updateRequest, script, setScript } = useContext(AppContext);
	const [requestTab, setRequestTab] = useState<number>(0);

	const getOnlyRequest = ["GET", "HEAD"].includes(request.method);

	return (
		<div className={"Request"}>
			<div className={"requestHeaderRoot"}>
				<Typography variant={"h5"}>ReQuest</Typography>
				<InstallPrompt />
			</div>
			<RequestBar />

			<Tabs
				disabled={loading}
				variant={"fullWidth"}
				value={requestTab}
				onChange={(e, value) => setRequestTab(value)}
			>
				<Tab disabled={loading} label="Params" />
				<Tab disabled={loading} label="Headers" />
				<Tab disabled={loading} label="Body" />
				<Tab disabled={loading} label="Script" />
			</Tabs>

			<div className={"requestTabsRoot"}>
				{requestTab === 0 && <Params />}
				{requestTab === 1 && <HeaderValues />}
				{
					requestTab === 2 &&
					<AceEditor
						className={"requestBodyEditor"}
						mode={"json"}
						theme={"tomorrow_night_eighties"}
						value={getOnlyRequest ? "Disabled for GET / HEAD" : request.body}
						onChange={(value) => updateRequest("body", value)}
						showGutter={true}
						readOnly={getOnlyRequest || loading}
						highlightActiveLine={true}
						maxLines={10}
						minLines={10}
						width={"100%"}
					/>
				}
				{
					requestTab === 3 &&
					<AceEditor
						className={"requestBodyResponse"}
						mode={"typescript"}
						theme={"tomorrow_night_eighties"}
						value={script}
						onChange={(value) => setScript(value)}
						readOnly={loading}
						showGutter={true}
						highlightActiveLine={true}
						maxLines={10}
						minLines={10}
						width={"100%"}
					/>
				}
			</div>
		</div>
	);
}

export default RequestSection;
