import { Button, MenuItem } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import React, { useContext } from 'react';
import { AppContext } from "../../context/app.context";
import { useKeyPress } from "../../hooks/app.hooks";
import { HttpMethods } from "../../models/httpConsts";
import './RequestBar.scss';

const RequestBar: React.FC = () => {
	const { loading, request, updateRequest, execute } = useContext(AppContext);
	const sendRequest = useKeyPress("F5");
	const hasUrl = request.url.trim().length > 0;
	if (sendRequest && hasUrl) execute();

	const paramString = request.params
		.filter(item => item.key)
		.map(item => item.key + "=" + item.value)
		.join("&");

	return (
		<div className={"RequestBar"}>
			<TextField
				disabled={loading}
				className={"requestMethod"}
				select
				label={"Method"}
				value={request.method}
				onChange={(e) => updateRequest("method", e.target.value)}
				variant={"outlined"}
			>
				{
					HttpMethods.map(option => (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					))
				}
			</TextField>
			<TextField
				disabled={loading}
				className={"requestUrl"}
				label={"Request URL"}
				value={request.url + (paramString.length > 0 ? ("?" + paramString) : "")}
				onChange={(e) => updateRequest("url", e.target.value.split("?")[0])}
				onKeyDown={(e) => {
					if (e.key === "Enter" && hasUrl) {
						execute();
					}
				}}
				variant={"outlined"}
			/>
			<Button
				className={"requestSend"}
				onClick={() => execute()}
				title={"Enter in URL input or F5"}
				disabled={!hasUrl || loading}
			>
				SEND
			</Button>
		</div>
	);
}

export default RequestBar;
