import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/App';
import './index.css';
import * as serviceWorker from './serviceWorker';

const theme = createMuiTheme({
	palette: {
		type: 'dark',
	},
});

ReactDOM.render(
	<ThemeProvider theme={theme}>
		<CssBaseline />
		<App />
	</ThemeProvider >,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
let config = {
	onUpdate: (reg: ServiceWorkerRegistration) => {
		let event = new Event("updateavailable");
		window.dispatchEvent(event);
		console.log("UPDATE", reg);
	}
}
serviceWorker.register(config);