import { IconButton, Tooltip } from "@material-ui/core";
import InstallIcon from "@material-ui/icons/OfflineBolt";
import React, { useEffect, useState } from 'react';
import { IBeforeInstallPromptEvent } from "./beforeinstall";

export interface SafariNavigator extends Navigator {
	standalone: boolean;
}

/**
 * ## Install App requirements:
 * 
 * ### manifest.json
 * * short_name or name
 * * icons must include a 192px and a 512px sized icons
 * * start_url
 * * display must be one of: fullscreen, standalone, or minimal-ui
 * 
 * ### requires https
 * 
 * ### service worker with fetch event handler
 * * *this can be enabled/registered in CRA index.tsx*s
 * 
 */
export const InstallPrompt: React.FC = () => {
	const [prompt, setPrompt] = useState<IBeforeInstallPromptEvent | Event | undefined>(undefined);
	useEffect(() => {
		// check for install flag
		let installed = localStorage.getItem("PWA_INSTALLED") === "1";
		// check if launched from already installed instance
		let homeScreenLaunch = window.matchMedia('(display-mode: standalone)').matches ||
			(window.navigator as SafariNavigator).standalone === true;

		// add event listener for when app is installed
		window.addEventListener('appinstalled', (evt) => {
			// set app installed flag
			localStorage.setItem("PWA_INSTALLED", "1");
			console.log('PWA:', 'installed');
		});

		// add event listener for install prompt fire
		window.addEventListener('beforeinstallprompt', (e) => {
			console.log("PWA:", "Install prompt ready");
			// Prevent Chrome 76 and later from showing the mini-infobar
			e.preventDefault();
			localStorage.removeItem("PWA_INSTALLED");
			// if not installed and launched from browser, set can install
			if (!installed && !homeScreenLaunch) {
				console.log("PWA:", "Can be installed");
				setPrompt(e);
			} else {
				console.log("PWA:", "Already installed");
			}
		});
	}, []);

	async function installApp() {
		let p = prompt as IBeforeInstallPromptEvent;
		if (p) {
			p.prompt();
			const result = await p.userChoice;
			if (result.outcome === 'accepted') {
				console.log("PWA:", "User Accepted");
			} else {
				console.log("PWA:", "User rejected");
			}
			setPrompt(undefined);
		} else {
			console.log("PWA:", "Install criteria not met");
		}
	}

	return (
		prompt ?
			<Tooltip title="Install PWA">
				<IconButton
					onClick={() => installApp()}
				>
					<InstallIcon />
				</IconButton>
			</Tooltip>
			: null
	);
};

export default InstallPrompt;