import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { useContext } from 'react';
import { AppContext } from "../../context/app.context";
import './ResponseHeaders.scss';

const ResponseHeaders: React.FC = () => {
	const { response } = useContext(AppContext);
	return (
		<Table size={"small"}>
			<TableHead>
				<TableRow>
					<TableCell>Key</TableCell>
					<TableCell>Value</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{response!.headers.map((row, index) => (
					<TableRow key={index}>
						<TableCell>{row.key}</TableCell>
						<TableCell>{row.value}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
}

export default ResponseHeaders;
