import { IconButton } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useContext } from 'react';
import { AppContext } from "../../context/app.context";
import { CommonHeaders } from "../../models/httpConsts";
import './HeaderValues.scss';

const HeaderValues: React.FC = () => {
	const { loading, request, updateRequest, updateKeyValue } = useContext(AppContext);

	return (
		<div className={"headersRoot"}>
			{
				request.headers.map((item, index) =>
					<div key={index} className={"keyValue"}>
						<Autocomplete
							disabled={loading}
							freeSolo
							autoComplete
							disableOpenOnFocus
							includeInputInList
							value={item.key}
							onChange={(e, value) => updateKeyValue(index, "headers", "key", request.headers, value)}
							className={"key"}
							options={CommonHeaders}
							renderInput={params => (
								<TextField
									{...params}
									fullWidth
									label={"Key"}
									variant={"outlined"}
								/>
							)}
						/>
						<TextField
							disabled={loading}
							className={"value"}
							label={"Value"}
							value={item.value}
							onChange={(e) => updateKeyValue(index, "headers", "value", request.headers, e.target.value)}
							variant={"outlined"}
						/>
						<IconButton
							className={"removeIcon"}
							disabled={request.headers.length <= 1 || loading}
							onClick={() => {
								request.headers.splice(index, 1);
								updateRequest("headers", request.headers);
							}}
						>
							<DeleteIcon />
						</IconButton>
					</div>
				)
			}
		</div>
	);
}

export default HeaderValues;
