import { useEffect, useState } from "react";

export function useKeyPress(targetKey: string) {
	const [keyPressed, setKeyPressed] = useState(false);
	function downHandler(e: KeyboardEvent) {
		if (e.key === targetKey) {
			e.preventDefault();
			e.stopPropagation();
			setKeyPressed(true);
		}
	}
	function upHandler(e: KeyboardEvent) {
		if (e.key === targetKey) {
			e.preventDefault();
			e.stopPropagation();
			setKeyPressed(false);
		}
	};
	useEffect(() => {
		window.addEventListener('keydown', (e) => downHandler(e));
		window.addEventListener('keyup', (e) => upHandler(e));
		return () => {
			window.removeEventListener('keydown', (e) => downHandler(e));
			window.removeEventListener('keyup', (e) => upHandler(e));
		};
	}, []);
	return keyPressed;
}