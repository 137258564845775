import { HttpKeyValue } from "./requestParam";

export default class HttpRequest {
	public url: string = "https://rickandmortyapi.com/api/";
	public path: string = "";
	public method: string = "GET";
	public headers: HttpKeyValue[] = [{ key: "", value: "" }];
	public params: HttpKeyValue[] = [{ key: "", value: "" }];
	public body: string | undefined = undefined;
	public contentType: string = "application/json";
	public script: string = "";
	[key: string]: string | Headers | HttpKeyValue[] | undefined;

	constructor(item?: any) {
		if (!item) return this;

		this.url = item.url ?? "https://rickandmortyapi.com/api/";
		this.path = item.path ?? "";
		this.method = item.method ?? "GET";
		this.headers = item.headers ?? [{ key: "", value: "" }];
		this.params = item.params ?? [{ key: "", value: "" }];
		this.body = item.body;
		this.script = item.script ?? "";
		this.contentType = item.contentType ?? "application/json";
	}
}