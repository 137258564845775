import { IconButton } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useContext } from 'react';
import { AppContext } from "../../context/app.context";
import './Params.scss';

const Params: React.FC = () => {
	const { loading, request, updateRequest, updateKeyValue } = useContext(AppContext);

	return (
		<div className={"paramsRoot"}>
			{
				request.params.map((item, index) =>
					<div key={index} className={"paramsKeyValue"}>
						<TextField
							disabled={loading}
							className={"paramsKey"}
							label={"Key"}
							value={item.key}
							onChange={(e) => updateKeyValue(index, "params", "key", request.params, e.target.value)}
							variant={"outlined"}
						/>
						<TextField
							disabled={loading}
							className={"paramsValue"}
							label={"Value"}
							value={item.value}
							onChange={(e) => updateKeyValue(index, "params", "value", request.params, e.target.value)}
							variant={"outlined"}
						/>
						<IconButton
							className={"paramsRemoveIcon"}
							disabled={request.params.length <= 1 || loading}
							onClick={() => {
								request.params.splice(index, 1);
								updateRequest("params", request.params);
							}}
						>
							<DeleteIcon />
						</IconButton>
					</div>
				)
			}
		</div>
	);
}

export default Params;
