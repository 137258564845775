import React, { useState } from "react";
import HttpRequest from "../models/httpRequest";
import HttpResponse from "../models/httpResponse";
import { HttpKeyValue } from "../models/requestParam";
import HttpService from "../services/httpService";

export interface IAppContext {
	loading: boolean;
	setLoading(loading: boolean): void;
	request: HttpRequest;
	response: HttpResponse | undefined;
	script: string;
	responseTime: number | undefined;
	setScript(script: string): void;
	updateRequest(name: string, value: string | Headers | HttpKeyValue[]): void;
	updateKeyValue(index: number, name: string, prop: keyof (HttpKeyValue), values: HttpKeyValue[], value: string): void;
	execute(): void;
}

export const AppContext = React.createContext<IAppContext>(
	{ ...{} as IAppContext }
);

export interface IAppProviderProps {

}

const http = new HttpService();

const AppProvider: React.FC<IAppProviderProps> = (props) => {
	const [script, setScript] = useState<string>("");
	const [responseTime, setResponseTime] = useState<number>();
	const [loading, setLoading] = useState(false);
	const [request, setRequest] = useState<HttpRequest>(new HttpRequest());
	const [response, setResponse] = useState<HttpResponse>();

	function updateRequest(name: string, value: string | Headers | HttpKeyValue[]) {
		request[name] = value;
		setRequest({ ...request });
	}

	function updateKeyValue(index: number, name: string, prop: keyof (HttpKeyValue), values: HttpKeyValue[], value: string) {
		if (index === values.length - 1) {
			values.push({ key: "", value: "" });
		}
		values[index][prop] = value;
		updateRequest(name, values);
	}

	async function execute() {
		setLoading(true);
		try {
			new Function("request", script)(request);
		} catch (e) {
			console.log(e);
		}
		let startTime = Date.now();
		let response = await http.execute(request);
		console.log(response);

		setResponseTime(Date.now() - startTime);
		setResponse(response);
		setLoading(false);
	}

	return (
		<AppContext.Provider value={{
			...props,
			loading,
			setLoading: (loading) => setLoading(loading),
			request,
			response,
			script,
			responseTime,
			setScript: (s) => setScript(s),
			updateKeyValue: (index, name, prop, values, value) => updateKeyValue(index, name, prop, values, value),
			updateRequest: (name, value) => updateRequest(name, value),
			execute: () => execute()
		}}>
			{props.children}
		</AppContext.Provider>
	);
}

export default AppProvider;