import { Typography } from "@material-ui/core";
import React from 'react';
import './NoResponse.scss';

const NoResponse: React.FC = () => {
	return (
		<div className={"noResponseRoot"}>
			<Typography variant={"subtitle2"}>Add a URL then hit send to get a response</Typography>
		</div>
	);
}

export default NoResponse;
