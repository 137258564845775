import React, { useEffect, useState } from 'react';
import AppProvider, { IAppProviderProps } from "../../context/app.context";
import RequestSection from "../RequestSection/RequestSection";
import ResponseSection from "../ResponseSection/ResponseSection";

export const AppRoot: React.FC = () => {
	const [updateAvailable, setUpdateAvailable] = useState(false);
	useEffect(() => {
		function onEvent(e: Event) {
			console.log(e);

			setUpdateAvailable(true);
		}
		window.addEventListener("updateavailable", onEvent);
		return () => {
			window.removeEventListener("updateavailable", onEvent);
		};
	}, []);
	return (
		<>
			<RequestSection />
			<ResponseSection />

			{/* {
				updateAvailable &&
				<div style={{
					padding: "1rem",
					backgroundColor: "black",
					color: "white"

				}}>
					New Update Available
				<button onClick={() => window.location.reload()}>Refresh</button>
				</div>
			} */}
		</>
	);
}

export const App: React.FC<IAppProviderProps> = (props) => {
	return (
		<AppProvider {...props}>
			<AppRoot {...props} />
		</AppProvider>
	);
}

export default App;
