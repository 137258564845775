import { HttpKeyValue } from "./requestParam";

export default class HttpResponse {
	public url: string = "";
	public status: number = 200;
	public statusText: string = "";
	public json: any;
	public headers: HttpKeyValue[] = [];
	public type: string = "";

	constructor(item?: Response) {
		if (!item) return this;

		this.url = item.url;
		this.status = item.status;
		this.statusText = item.statusText;
		let headers: HttpKeyValue[] = [];
		item.headers.forEach((value, key) => headers.push({ key, value }));
		this.headers = headers;
		this.type = item.type;
	}
}