export const HttpMethods = [
	"GET",
	"POST",
	"PUT",
	"PATCH",
	"DELETE",
	"HEAD",
	"OPTIONS"
];

export const CommonHeaders = [
	"WWW-Authenticate",
	"Authorization",
	"Proxy-Authenticate",
	"Proxy-Authorization",
	"Age",
	"Cache-Control",
	"Clear-Site-Data",
	"Expires",
	"Pragma",
	"Warning",
	"Accept-CH",
	"Accept-CH-Lifetime",
	"Early-Data",
	"Content-DPR",
	"DPR",
	"Device-Memory",
	"Save-Data",
	"Viewport-Width",
	"Width",
	"Last-Modified",
	"ETag",
	"If-Match",
	"If-None-Match",
	"If-Modified-Since",
	"If-Unmodified-Since",
	"Vary",
	"Connection",
	"Keep-Alive",
	"Accept",
	"Accept-Charset",
	"Accept-Encoding",
	"Accept-Language",
	"Expect",
	"Max-Forwards",
	"Cookie",
	"Set-Cookie",
	"Cookie2",
	"Set-Cookie2",
	"Access-Control-Allow-Origin",
	"Access-Control-Allow-Credentials",
	"Access-Control-Allow-Headers",
	"Access-Control-Allow-Methods",
	"Access-Control-Expose-Headers",
	"Access-Control-Max-Age",
	"Access-Control-Request-Headers",
	"Access-Control-Request-Method",
	"Origin",
	"Service-Worker-Allowed",
	"Timing-Allow-Origin",
	"X-Permitted-Cross-Domain-Policies",
	"DNT",
	"Tk",
	"Content-Disposition",
	"Content-Length",
	"Content-Type",
	"Content-Encoding",
	"Content-Language",
	"Content-Location",
	"Forwarded",
	"X-Forwarded-For",
	"X-Forwarded-Host",
	"X-Forwarded-Proto",
	"Via",
	"Location",
	"From",
	"Host",
	"Referer",
	"Referrer-Policy",
	"User-Agent",
	"Allow",
	"Server",
	"Accept-Ranges",
	"Range",
	"If-Range",
	"Content-Range",
	"Cross-Origin-Opener-Policy",
	"Cross-Origin-Resource-Policy",
	"Content-Security-Policy",
	"Content-Security-Policy-Report-Only",
	"Expect-CT",
	"Feature-Policy",
	"Public-Key-Pins",
	"Public-Key-Pins-Report-Only",
	"Strict-Transport-Security",
	"Upgrade-Insecure-Requests",
	"X-Content-Type-Options",
	"X-Download-Options",
	"X-Frame-Options",
	"X-Powered-By",
	"X-XSS-Protection",
	"Last-Event-ID",
	"NEL",
	"Ping-From",
	"Ping-To",
	"Report-To",
	"Transfer-Encoding",
	"TE",
	"Trailer",
	"Sec-WebSocket-Key",
	"Sec-WebSocket-Extensions",
	"Sec-WebSocket-Accept",
	"Sec-WebSocket-Protocol",
	"Sec-WebSocket-Version",
	"Accept-Push-Policy",
	"Accept-Signature",
	"Alt-Svc",
	"Date",
	"Large-Allocation",
	"Link",
	"Push-Policy",
	"Retry-After",
	"Signature",
	"Signed-Headers",
	"Server-Timing",
	"SourceMap",
	"Upgrade",
	"X-DNS-Prefetch-Control",
	"X-Firefox-Spdy",
	"X-Pingback",
	"X-Requested-With",
	"X-Robots-Tag",
	"X-UA-Compatible"
].sort();