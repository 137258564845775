import { CircularProgress, Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-typescript";
import "ace-builds/src-noconflict/theme-tomorrow_night_eighties";
import React, { useContext } from 'react';
import { AppContext } from "../../context/app.context";
import './ResponseDetails.scss';

const ResponseDetails: React.FC = () => {
	const { loading, response, responseTime } = useContext(AppContext);

	function formatBytes(decimals: number = 1) {
		let bytes = response?.json.length;
		if (bytes === 0) return '0B';
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		let size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
		return size;
	}

	return (
		response
			? <div className={"responseDetailsRoot"}>
				<Typography variant={"h5"}>Response</Typography>
				{
					!loading
						? <div className={"responseDetails"}>
							<div className={"responseDetail"}>
								<Typography variant={"body1"} style={{ fontSize: "0.75rem" }}>Status: <span style={{ color: green[500] }}>{response.status}</span></Typography>
							</div>
							<div className={"responseDetail"}>
								<Typography variant={"body1"} style={{ fontSize: "0.75rem" }}>Time: <span style={{ color: green[500] }}>{responseTime}ms</span></Typography>
							</div>
							<div className={"responseDetail"}>
								<Typography variant={"body1"} style={{ fontSize: "0.75rem" }}>Size: <span style={{ color: green[500] }}>{formatBytes()}</span></Typography>
							</div>
						</div>
						: <CircularProgress />
				}
			</div>
			: null
	);
}

export default ResponseDetails;
